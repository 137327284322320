/* css from  */
:root {
  --primaryColor: #df3458;
  --titleColor: #212121;
  --textColor: #585f5f;
  --whiteCreamColor: #fafafa;
  --primaryColorLight: #d2f4f1;
  --primaryColorLight2: #f5faff;
  --black: #323232;
}
.body-wrapper {
  min-height: 100vh;
}
li {
  list-style-type: none;
}
ul {
  padding-left: 0 !important;
}
.object-fit-contain {
  object-fit: contain !important;
}
.mobile-view {
  display: none !important;
}
.desktop-view {
  display: block !important;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* 30% black overlay */
}
.overlay2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); /* 30% black overlay */
}
/* font */
/* .fw-bold {
  font-weight: bold;
} */
.fw-semibold {
  font-weight: 600;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-16 {
  font-size: 16px !important;
}
/* background */

.bg-disabled {
  background-color: #8dc1ba !important;
}

.bg-primary-color-light {
  background-color: var(--primaryColorLight) !important;
}
.bg-primary-color {
  background-color: var(--primaryColor) !important;
}
.bg-white-cream {
  background-color: var(--whiteCreamColor) !important;
}
.bg-black {
  background-color: var(--black) !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-primary-color-light-2 {
  background-color: var(--primaryColorLight2);
}
.bg-pastel-gradient {
  background: linear-gradient(#30c6b5, #176058);
}
.bg-blue-gradient {
  background: linear-gradient(#50b9e7, #115c7e);
}

.bg-instagram {
  background: linear-gradient(to right, #9452a4 0%, #d94c75 50%, #f9c375 100%);
}
.bg-tiktok {
  background: linear-gradient(to right, #00f6ef 0%, #ff004f 100%);
}
.bg-youtube {
  background: linear-gradient( to right, #FF0000 0%, #9a0000 100%);
}
.bg-facebook {
  background: linear-gradient( to right, #4267B2 0%, #5c90f8 100%);
}

.bg-x {
  background: linear-gradient(to right, #1da1f2 0%, #1675b0 100%);
}

.bg-snapchat {
  background: linear-gradient(to right, #fffc00 0%, #b2af00 100%);
}


.max-width {
  max-width: 1200px;
}
/* color */
.color-text-color {
  color: var(--textColor) !important;
}
.color-title-color {
  color: var(--titleColor) !important;
}
.color-black {
  color: var(--black) !important;
}
.color-white-cream {
  color: var(--whiteCreamColor) !important;
}

.color-white {
  color: #ffffff !important;
}

.active-nav {
  color: var(--primaryColor);
}

.cursor-pointer {
  cursor: pointer !important;
}
.icon-wrapper {
  width: 50px;
  height: 50px;
  border: 1px solid var(--primaryColorLight2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.footer-title {
  font-weight: bold;
  font-size: 20px;
  font-family: "Maven Pro", sans-serif;
  color: var(--whiteCreamColor);
  margin-bottom: 20px;
}
/* Hero Section */

.bg-hero-section {
  width: 100%;
  /* min-height: 50vh; */
  position: relative;
  /* Set the SVG background */
  /* background-image: url("./../../public/asset/Hero_section_BG.svg"); */
  /* background-image: url("./../../public/asset/hero-BG.png");
  background-size: cover; 
  background-position: center;  */
  background-color: #f5faff;
}

.hero-title {
  font-size: 56px;
  color: var(--titleColor);
  margin-bottom: 20px;
}

.hero-description {
  font-size: 16px;
  color: var(--titleColor);
  margin-bottom: 20px;
}
.rad-5 {
  border-radius: 5px;
}
.rad-10 {
  border-radius: 10px;
}
/* Hero section 1 */
.image-wrapper {
  max-width: 400px;
  position: relative;
}
.image-wrapper img {
  border-radius: 5px;
}
.image-wrapper::before {
  content: "";
  position: absolute;
  width: 400px;
  height: 263px;
  background-color: var(--primaryColor);
  border-radius: 5px;
  right: 33px;
  bottom: 35px;
  z-index: -1;
}
.image-wrapper-2 {
  max-width: 400px;
  position: relative;
}
.hero-image-2 {
  right: 132px;
  bottom: -15%;
  /* top: 100px; */
  max-width: 75%;
  z-index: 1;
  border: 2px solid white;
}
/* faq */
.mb-200 {
  margin-bottom: 200px;
}
.faq-title {
  font-size: 40px;
}
.faq-desc {
  max-width: 350px;
  font-size: 16px;
}
.faq-accordion {
  font-size: 20px;
}
.accordion-item {
  border: 0 !important;
  border-bottom: 1px solid rgba(105, 105, 105, 0.5) !important;
}
.accordion-button.collapsed::after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("./../assets/svg/plus-square.svg") !important;
  background-size: cover;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("./../assets/svg/minus1.svg") !important;
}
/* swipper */

.testimonial-title {
  font-family: "Maven Pro", sans-serif;
  font-size: 22px;
  font-weight: bold;
}
.testimonial-sub {
  font-family: "Maven Pro", sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.swiper-container {
  height: 400px;
}
.swiper {
  width: 100% !important;
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.swiper-button-prev,
.swiper-button-next {
  cursor: pointer !important;
}
.swiper-button-prev:after {
  content: "";
  display: inline-block;
  padding-right: 55px !important;
  width: 55px !important;
  height: 55px !important;
  background-image: url("./../assets/image/swiperLeft.png") !important;
  background-size: cover;
}
.swiper-button-disabled {
  opacity: 0.75 !important;
}
.swiper-button-next:after {
  content: "";
  display: inline-block;
  padding-left: 55px !important;
  width: 55px !important;
  height: 55px !important;
  background-image: url("./../assets/image/swiperRight.png") !important;
  background-size: cover;
}
.testimonial {
  /* display: flex;
  flex-direction: row; */
  /* font-family: ; */
}
.testimonial h4 {
  font-size: 22px;
  font-weight: bold;
}

.testimonial-img {
  max-width: 282px;
  height: 181px;
  border-radius: 6px;
}
.testimonial p {
  font-size: 20px;
}
.hc-card-title {
  font-weight: 600;
  font-size: 24px;
}
.hc-card-desc {
  font-size: 16px;
}
.hc-title {
  font-size: 40px;
}
.hero-section-2 {
  min-height: 250px;
}
/* Member Stats */
.member-stat-content {
  background-image: url("./../assets/svg/MemberStatInflux.svg");
  background-size: cover;
  min-height: 320px; /* Set the height to fill the viewport */
}
.member-stat-wrapper {
  margin-bottom: 200px;
}
.member-stat-card {
  position: absolute;
  top: 240px;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 150px;
}
.member-stat-card .card {
  width: 18rem;
}
.child-div {
  margin-bottom: 20px; /* Add spacing between child divs */
  max-width: 250px;
}
.child-div p {
  font-size: 40px;
}
.card-img-top {
  width: 46px !important;
}
.card {
  border: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Change values as needed */
}

/* Partner Logo */
.partner-title {
  font-size: 20px;
  color: white;
}
/* Contact Us */
.contact-us-left-bg {
  background-image: url("./../assets/svg/contactUsBg.svg");
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
}

.contact-us-container span {
  color: #c9c9c9;
}

.contact-us-title {
  color: white;
  font-size: 28px;
}

.contact-us-icon-wrapper,
.contact-us-icon-wrapper-instagram,
.contact-us-icon-wrapper-twitter {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
}

.contact-us-icon-wrapper,
.contact-us-icon-wrapper-twitter {
  background: var(--primaryColor);
}
.contact-us-icon-wrapper-instagram {
  background: white;
}


.contact-us-right-title-container h4 {
  font-size: 56px;
}
.page-header-container {
  width: 100%;
  min-height: 10vh; /* Adjust as needed */
  position: relative;

  /* Set the SVG background */
  background-image: url("../assets/image/page-header-bg.png");
  background-size: cover; /* Adjust as needed */
  background-position: center; /* Adjust as needed */
}
.page-header-container h2 {
  font-size: 54px;
}
.progress-bar {
  background-color: var(--primaryColor) !important;
}
.top-display-bar {
  position: absolute;
  top: -40px;
  right: -20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: var(--primaryColor);
  color: white;
  border-radius: 10px;
}
.preview-img {
  max-width: 100px;
  width: 100%;
  height: auto;
  object-fit: contain;
}
/* Media Query */
@media (max-width: 768px) {
  .hero-title {
    font-size: 36px;
    margin-bottom: 10px;
  }

  .title-40 {
    font-size: 34px;
    line-height: 34px;
  }
  .faq-title {
    margin-bottom: 20px;
  }

  .swiper {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .hc-title {
    font-size: 34px;
    line-height: 34px;
  }
  .full-width-mobile {
    width: 100%;
  }
  .mobile-view {
    display: block !important;
  }
  .desktop-view {
    display: none !important;
  }
  .image-wrapper {
    text-align: center; /* Center image on mobile */
    margin-bottom: 20px; /* Add some spacing between image and header */
  }
  .w-md-100 {
    width: 100% !important;
  }
  .member-stat-card {
    position: relative;
    top: 0;
    margin-bottom: 50px;
  }
  .member-stat-wrapper {
    margin-bottom: 0;
  }

  .testimonial-img {
    max-width: 200px;
    height: 200px;
  }

  .testimonial-title {
    font-size: 34px;
  }

  .testimonial p {
    font-size: 16px;
  }

  /* ------------
  ------------------
  -------------- */
}

/* HeroSection AboutUS - [Fuad] */
.doctor-img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}

/* Header.css*/
.nav-item {
  font-family: "Roboto", sans-serif;
  text-align: start;
  padding: 10px;
  margin: 5px;
  margin-left: 0;
  padding-left: 0;
  border-radius: 10px;
  border: 0;
}

.nav-item-link {
  font-size: 16px;
  text-decoration: none;
  display: inline-block;
  padding: 5px 10px;
  transition: all 0.3s ease-in-out;
}

.nav-item-link:hover {
  transform: translateY(-4px);

  /* border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

/* .active-nav {
  color: white;
  border-bottom: 2px solid #f0f0f0;
} */

.inactive-nav {
  color: #333;
}

/* Animation for hover effect */
@keyframes slideUp {
  from {
    transform: translateY(10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.nav-link:hover {
  font-weight: bold;
  /* animation: slideUp 0.3s forwards; */
}

/* nav active svg */

.active-nav-svg {
  fill: var(--primaryColor);
}

.active-nav-label {
  /* border-bottom: 2px solid var(--primaryColor); */
}

.EZDrawer__container {
  width: 180px !important;
  /* height: 300px !important; */
}

/* Design fix  */
.about-us-hero-img {
  max-width: 486px;
  object-fit: cover;
}

.about-us-time-count {
  font-size: 48px;
}

@media (max-width: 768px) {
  .top-display-bar {
    padding-left: 20px;
    padding-right: 20px;
  }

  .about-us-time-count {
    font-size: 26px;
  }
}

.fs-40 {
  font-size: 40px;
}

.bg-contact-us {
  width: 100%;
  background-image: url("./../../public/asset/contact-us-BG.png");
  background-size: cover;
  background-position: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

.why-choose-us-top-image {
  position: absolute;
  right: 110px;
  bottom: -10%;
  z-index: 1;
  border: 4px solid white;
  width: 266px !important;
  height: 224px !important;
  border-radius: 10px;
}

/* ALvee web start */

.influencer-card {
  position: relative;
  width: 300px;
  /* border: 1px solid #ddd; */
  border-radius: 26px;
  /* background-color: #fff; */
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-bottom: 8px solid #8d8d8d;
  overflow: hidden;
  transition: all 0.3s ease;
}

.influencer-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  border-bottom: 8px solid var(--primaryColor);
}

.image-container,
.image-container1 {
  position: relative;
  width: 115px;
  height: 115px;
  margin: 0 auto; /* Center the image */
  /* Bootstrap utility: .rounded-circle for border-radius: 50% */
  position: relative;
  border-radius: 50%;
  overflow: hidden; /* Ensure the image doesn't exceed the container */
  border-right: 5px solid var(--primaryColor);
  border-bottom: 3px solid var(--primaryColor);
  border-top: 3px solid var(--primaryColor);
  border-left: none; /* Partial red border */
}

.image-container1 {
  width: 170px;
  height: 170px;
  border-right: none;
  border-bottom: 5px solid var(--primaryColor);
  border-top: none;
  border-left: 5px solid var(--primaryColor);
}

.influencer-img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the container without stretching */
  object-position: center; /* Center the image */
  border-radius: 50%; /* Make the image circular */
}

.influencer-socials {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 15px;
  /* Bootstrap utility: .d-flex, .justify-content-center, .gap-3 */
}

.social-icon {
  color: #333;
  font-size: 1.5rem;
  transition: color 0.3s ease;
  /* Bootstrap utility: .fs-3 for font-size: 1.5rem */
}

.social-icon:hover {
  color: #007bff;
}

/* Featured Badge */
.featured-badge {
  position: absolute;
  top: 20px;
  left: -30px;
  background-color: var(--primaryColor);
  color: white;
  padding: 5px 40px;
  transform: rotate(-45deg);
  font-size: 0.8rem;
  font-weight: bold;
  /* Bootstrap utility: .bg-danger for background-color: red */
  /* Bootstrap utility: .text-white for color: white */
  /* Bootstrap utility: .fw-bold for font-weight: bold */
}

.icon-wrapper-influx svg {
  width: 30px;
  height: 30px;
}

.partner {
  position: relative;
  margin-bottom: -80px;
  z-index: 1;
}

.pt-140 {
  padding-top: 140px;
}

/* Service Card */
.service-card {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}

.service-card:hover {
  transform: translateY(-5px);
}

.service-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); /* Replace with any background color or use RGBA for transparency */
  z-index: 1;
}
.service-card > * {
  position: relative;
  z-index: 2;
}



.service-image-container {
  width: 100%;
  height: 100px; /* Ensure image retains its aspect ratio */
  overflow: hidden;
}
.service-image-container1 {
  margin:  0 auto;
  width: 100%;
  max-width: 400px;

  max-height: 250px; /* Ensure image retains its aspect ratio */
  overflow: hidden;
}
/* Package Card */
.package-card {
  transition: transform 0.3s ease-in-out;
  position: relative;
  overflow: hidden;
}



.package-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(256, 256, 256, 0.1); /* Replace with any background color or use RGBA for transparency */
  z-index: 1;
}
.package-card > * {
  position: relative;
  z-index: 2;
}

.package-image-container {
  max-width: 500px;
  max-height: 500px;
  width: 100%;
  height: 100%; /* Ensure image retains its aspect ratio */
  overflow: hidden;
}

/* ALvee web end */

/* add web css - [Fuad] */

.partner-container {
  padding-top: 40px;
  padding-bottom: 40px;
}

.testimonial-bg {
  width: 282px;
  height: 380px;
  border-radius: 30px;
}

.testimonial-inner-bg {
  width: 342px;
  height: 330px;
  border-radius: 10px;
}
/* .testimonial-bg{
  width: 210px;
  height: 280px;
}

.testimonial-inner-bg{
  width: 252px;
  height: 230px;
  border-radius: 10px;
} */

.review {
  font-family: "Maven Pro", sans-serif;
  font-size: 10px;
  line-height: 24px;
}

.review-heading {
  font-family: "Maven Pro", sans-serif;
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
}
