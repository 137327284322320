@font-face {
  font-family: "Nexa";
  src: url("../fonts/Nexa Regular.otf") format("opentype");
}

:root {
  --white-cream: #fafafa;
  --white-border: #d9d9d9;
  --gray-dim: #b3b3b3;

  --transparent-color: rgba(0, 0, 0, 0);
  --primary-color: #df3459;
  --secondary-color: #f5faff;
  --primary-color-dark: #192943;
  --focus-color: #df3458;
  --highlight-color: #df345933;
  --title-color: #212121;
  --text-color: #585f5f;
  --text-menu-color: #d4d3d3;
  /* --sub-menu-color: #569e96; */
  --sub-menu-color: #192943;
  --primary-gradient: linear-gradient(to bottom, #df3458, #360e17);
  --primary-dashboard: #192943;
}

.inner-panel {
  font-family: "Nexa", sans-serif !important;
  color: var(--text-color);
}
body {
  background-image: url("../../public//asset/background.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* CSS for Stepper start */

.stepper-container1 {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  gap: 50px;
  position: relative; /* Ensures absolute positioning is relative to this container */
}

@media (max-width: 768px) {
  .stepper-container1 {
    display: block;
  }
}

.step1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1; /* Ensures the icons and texts are above the lines */
}

.step1:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 30%;
  right: -50px; /* Center the line to the next element */
  width: 50px; /* Adjust based on gap */
  height: 2px;
  background-color: #ccc; /* Default line color */
  z-index: 0;
}

@media (max-width: 768px) {
  .step1:not(:last-child)::after {
    display: none;
  }
}

.step1.active::after {
  background-color: #007bff; /* Active line color */
}

.step-icon1 {
  color: #ccc; /* Inactive steps */
  font-size: 24px;
}

.step1.active .step-icon1 {
  color: #007bff; /* Active steps */
}

.step-text1 {
  margin-top: 8px;
  font-size: 12px;
  color: gray;
}

.step1.active .step-text1 {
  color: black;
}

/*  CSS for Stepper end */

/* CSS for Modal */

.custom-modal {
  height: 150px;
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--secondary-color);
  padding: 20px;
  z-index: 100;
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.link-style {
  text-decoration: none; /* Remove underline */
  color: var(--text-color); /* Set text color to black */
}
.link-style:hover {
  text-decoration: underline; /* Remove underline */
  color: var(--primary-color); /* Set text color to black */
}

.disabled-link {
  pointer-events: none;
  color: gray;
  text-decoration: none;
  cursor: default;
}

img {
  object-fit: cover;
}

.icon-40 svg {
  width: 40px;
  height: 40px;
}

.icon-text-color svg {
  fill: var(--text-color);
}

.col {
  padding: 0;
}
.text-title {
  color: var(--title-color);
}
.text-text {
  color: var(--text-color) !important;
}

.text-menu {
  color: var(--text-menu-color);
}
.text-primary-color {
  color: var(--primary-color);
}

/* Custom Font */
.fs-8 {
  font-size: 8px;
}
.fs-10 {
  font-size: 10px;
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}

.fw-900 {
  font-weight: 900;
}
.fw-700 {
  font-weight: 700;
}
.fw-600 {
  font-weight: 600;
}
.fw-500 {
  font-weight: 500;
}
.fw-400 {
  font-weight: 400;
}
.fw-300 {
  font-weight: 300;
}

.py-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

/* Custom backgrounds */
.bg-overlay {
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-primary-Dashboard {
  background: var(--primary-dashboard);
}
.bg-primary-gradient {
  background: var(--primary-gradient);
}
.bg-primary-gradient:disabled {
  background: var(--primary-gradient);
  opacity: 0.5;
}
/* .bg-secondary-gradient {
  background: var(--secondary-gradient);
} */
.bg-primary-color {
  background: var(--primary-color);
}
.bg-primary-color-dark {
  background: var(--primary-color-dark);
}
.bg-top {
  background: rgba(118, 183, 175, 1);
}
.bg-secondary-color {
  background: var(--secondary-color) !important;
}
.bg-highlight {
  background-color: var(--highlight-color);
}
.bg-white-cream {
  background-color: var(--white-cream);
}
.bg-blue-faded {
  background-color: var(--blue-faded);
}
.bg-admin-gradient,
.bg-Advertiser-gradient,
.bg-Influencer-gradient {
  background: linear-gradient(
    90deg,
    hsla(209, 45%, 89%, 1) 0%,
    hsla(327, 23%, 92%, 1) 100%
  );
}

/* Custom Border */
.border-primary-color {
  border-color: var(--primary-color) !important;
}
.border-white {
  border-color: var(--white-border) !important;
}

/* Custom width/height */
.mb-170px {
  margin-bottom: 170px;
}
.mt-120px {
  margin-top: 120px;
}
.pb-170px {
  padding-bottom: 170px;
}
.h-100px {
  height: 100px;
}
.h-160px {
  min-height: 160px;
  max-height: 160px;
}
.h-400px {
  height: 400px;
}
.h-600px {
  height: 600px;
}

.min-h-600px {
  min-height: 600px;
}

.min-height-84 {
  min-height: 84px;
}

.w-249px {
  min-width: 249px;
  max-width: 249px;
}
.w-300px {
  min-width: 300px;
  max-width: 300px;
}
.w-500px-inputField {
  min-width: 500px;
  max-width: 500px;
}
.w-min-250px {
  min-width: 250px;
}
.w-h-24px {
  width: 24px;
  height: 24px;
}
.w-h-33px {
  width: 33px;
  height: 33px;
}
.w-h-44px {
  width: 44px;
  height: 44px;
}
.w-h-50px {
  width: 50px;
  height: 50px;
}
.w-h-50px-max-min {
  max-width: 50px;
  min-width: 50px;

  max-height: 50px;
  min-height: 50px;
}
.input-wrapper-image {
  max-width: 200px;
  width: 100%;
  max-height: 300px;
  height: 100%;
}
.w-h-92px {
  width: 92px;
  height: 92px;
}
.w-h-130px {
  width: 130px;
  height: 130px;
}
.w-h-250px {
  width: 250px;
  height: 250px;
}
.w-h-max-500px {
  max-width: 500px;
  max-height: 500px;
}
.w-110px {
  width: 110px;
}

.w-94px {
  width: 94px;
}
.w-45 {
  width: 45%;
}
.w-10 {
  width: 10%;
}
.w-35 {
  width: 35%;
}
.w-25 {
  width: 25%;
}
.w-30 {
  width: 30%;
}
/* custom font */
.fs-14 {
  font-size: 14px;
}
.fs-12 {
  font-size: 12px;
}

/* Custom sidebar */
/* .css-dip3t8 {
  background: var(--primary-gradient);
} */

/* SideBar Styles */
.z-index-10 {
  z-index: 10;
}
.sideBar-menu .active {
  background-color: var(--focus-color);
}
.sideBar-menu .active a {
  color: white;
}
.sideBar-menu .active .menu-svg svg > path {
  fill: white;
}
.sideBar-menu .active .menu-svg svg > path {
  fill: white;
}
.sideBar-menu .active .menu-svg-1 svg > path {
  stroke: white;
}
.menu-svg svg > path {
  fill: var(--text-menu-color);
}
.menu-svg-1 svg > path {
  stroke: var(--text-menu-color);
}
.menu-svg-2 svg > circle {
  fill: var(--text-menu-color);
}
.menu-svg-3 svg > g > path {
  fill: var(--text-menu-color);
}

.sideBar-menu > ul > li {
  padding: 10px 15px;
  border-radius: 6px;
}

.arrow-expanded svg {
  transform: rotate(0deg);
  transition: transform 0.7s ease-in-out;
}

.arrow-collapsed svg {
  transform: rotate(-180deg);
  transition: transform 0.7s ease-in-out;
}

/* End of SideBar */

/* Topbar Styles */
.search button.btn:focus {
  border-color: transparent;
}
.search input:focus {
  box-shadow: none;
  outline: none;
}

/* End of Topbar */

/* DashBoard Styles */

.dashboard-card-list {
  overflow-y: auto;
  max-height: 220px;
}

.row-height {
  max-height: 400px !important;
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

/* Stats Card */
.stats-card:first-child {
  background: linear-gradient(to right, #df3458, #ea7b92);
  border-radius: 16px 0 0 0;
}
.stats-card:first-child span,
.stats-card:first-child p {
  color: white;
}

.stats-card:nth-child(1) {
  border-right: 1px solid var(--white-border);
  border-bottom: 1px solid var(--white-border);
}
.stats-card:nth-child(2) {
  border-bottom: 1px solid var(--white-border);
}
.stats-card:nth-child(3) {
  border-right: 1px solid var(--white-border);
}

/* Pagination Styles starts */
.custom-page-link,
.custom-previous-link,
.custom-next-link {
  text-decoration: none;
  color: var(--text-color);
  font-size: 14px;
  padding: 8px 12px;
  margin-right: 6px;
  border: 1px solid #c7c7c7;
  border-radius: 6px;
  transition: background-color 0.3s;
}
.custom-previous-link,
.custom-next-link,
.active-page-link,
.custom-page-link:hover,
.custom-previous-link:hover,
.custom-next-link:hover {
  color: white;
  background-color: var(--primary-color);
}
.active-page-link {
  cursor: not-allowed;
  border: none;
}
.custom-previous-link {
  margin-right: 12px;
}
.custom-next-link {
  margin-left: 6px;
}

.disabled-page-link {
  cursor: not-allowed;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  background: none;
}
/* End of Pagination */

/* Clinic management */
.table-filter svg > path {
  stroke: var(--text-color);
}

/* CMS Styles */
.cms-card {
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 30px;
  border: 1px solid var(--white-border);
  background-color: var(--secondary-color);
}
.cms-content {
  height: 500px;
  overflow-y: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-right: 10px;
}

.cms-form label {
  color: var(--titleColor);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.cms-form input,
.cms-form textarea,
.cms-form select {
  border-radius: 6px;
  height: 60px;
  border: 1px solid #adadad;
  padding: 16px 48px 16px 32px;
  width: 100%;
  outline: none;
}

.cms-form textarea {
  height: 120px;
}

.cms-form input:focus,
.cms-form textarea:focus {
  border: 2px solid #919099 !important;
}
.cms-form .form-item {
  margin-bottom: 20px;
}

.custom-select {
  padding-right: 30px; /* Adds space on the right */
  appearance: none; /* Hides the default arrow/caret */
  background-image: url("data:image/svg+xml;charset=US-ASCII,<svg ...>"); /* Add a custom arrow/caret */
  background-repeat: no-repeat;
  background-position: right 10px center; /* Adjust the position */
  background-size: 10px; /* Size of the caret */
}

.custom-select:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.error {
  color: var(--primary-color);
}

/* Order management */
.order-message {
  padding: 20px;
}
.order-message svg {
  transform: scale(3);
}

.message-badge {
  position: absolute;
  top: -12px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 10px;
}

.overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  overflow: auto;
}

.chat-conversation-drawer {
  position: fixed;
  bottom: 40px; /* Adjust distance from the bottom as needed */
  right: 40px; /* Adjust distance from the right as needed */
  max-width: 500px; /* Adjust maximum width as needed */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0s linear 0.3s;
  z-index: 1000; /* Ensure the drawer appears above the overlay */
}

.chat-conversation-drawer.open {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.chat-body {
  padding: 10px;
  max-height: 600px; /* Adjust maximum height as needed */
  overflow-y: auto;
}

.chat-header h3 {
  margin: 0;
  font-size: 18px;
}

.chat-header button {
  background: none;
  border: none;
  cursor: pointer;
}

/* order details */
.btn-1 {
  border: 1px solid var(--primary-color);
  font-weight: 600;
  font-size: smaller;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.btn-2 {
  background-color: var(--primary-color);
  color: white;
  font-size: smaller;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 10px;
}

.btn-3 {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  font-weight: 600;
  font-size: smaller;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
}
.btn-1:hover,
.btn-2:hover,
.btn-3:hover {
  background-color: var(--sub-menu-color);
  color: white;
}

.css-mq9gqg-ProgressBar {
  background-color: var(--primary-color) !important;
  margin-top: 4px !important;
  margin-left: -90px;
}
.css-qx19ch-ProgressMarker {
  background-color: var(--primary-color) !important;
  height: 15px !important;
  width: 15px !important;
}

/* Media Queries */
@media (min-width: 992px) {
  .w-lg-25 {
    width: 25%;
  }
}

@media (max-width: 991.98px) {
  .w-lg-25 {
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .cms-card {
    width: 340px;
  }
}
/* Message */

.message-svg svg {
  fill: var(--primary-color);
}

.width {
  width: 400px;
}

.bg-color {
  background: #30c6b5;
}

.w-33 {
  width: 33.33%;
}

.w-66 {
  width: 66.66%;
}

.search-icon {
  stroke: #fafafa !important;
}
.outline-none {
  outline: none !important;
}

.outline-none:focus {
  outline: none !important;
  border: var(--text-color);
}
.nav-link {
  color: black !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: black !important;
  background-color: #fd2d2d40 !important;
}

/* ---------------Add styles dashboard ---[Fuad] */

.table-container {
  max-height: 180px;
  overflow-y: auto;
}

.pdf-div {
  padding: 50px;
  background-color: #dedede;
  margin-top: 50px;
}
canvas.react-pdf__Page__canvas {
  margin-bottom: 50px;
  min-height: 400px !important;
  width: auto !important;
}

/* Custom Scrollbar */

.custom-scroll::-webkit-scrollbar {
  width: 4px !important;
}

.custom-scroll::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #c3c3c3 !important;
  border-radius: 3px !important;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #8a8a8a !important;
}

/* Transaction details */

.transaction-details-container {
  padding: 20px;
}

.transaction-details-row {
  display: flex;
  justify-content: space-between;
}

.transaction-details-column {
  flex: 1;
  padding: 10px;
}

.section-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.detail-label {
  font-weight: bold;
}

.error-message {
  color: red;
  font-weight: bold;
}

/* dropdown */

/* .input-wrapper-dropdown {
  position: relative;
}

.dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
} */

/* Datalist styles */

.country-code-list {
  max-height: 200px !important;
  overflow-y: auto !important;
}

/* -------------------------
---------Advertiser panel---------- */
/* Basic container setup */

.advertiser-setting-container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.setting-menu-container {
  flex: 1;
  min-width: 250px;
  max-width: 300px;
  margin-right: 20px;
}

.setting-content-container {
  flex: 2;
  min-width: 300px;
}

/* Menu Items */
.setting-menu-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s;
}

.setting-menu-item:hover,
.setting-menu-item.active {
  background-color: #e9ecef;
}

.setting-menu-item i {
  margin-right: 10px;
}

/* Content */
.user-account-section {
  display: flex;
  flex-direction: column;
}

.image-upload-container {
  margin-bottom: 20px;
}

.square-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.image-placeholder {
  width: 150px;
  height: 150px;
  background-color: #ccc;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-info-form .form-group {
  margin-bottom: 15px;
}

.user-info-form input {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Responsive Design */
@media (max-width: 768px) {
  .advertiser-setting-container {
    flex-direction: column;
  }
  .setting-menu-container {
    margin-bottom: 20px;
  }
}

/* ------------------------stepper---------- */

/* Stepper Container */
.stepper {
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

/* Steps Container */
.steps-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.steps {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  gap: 10px;
}

/* Individual Step Styling */
.step {
  flex: 1;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  background-color: #e0e0e0;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s, background-color 0.3s, transform 0.3s;
  border-color: white;
}

.step.active {
  opacity: 1;
  background-color: #4caf50;
  color: white;
  transform: scale(1.05);
}

.step:hover {
  opacity: 1;
  transform: scale(1.03);
}

.step-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* Step Number Styling */
.step-number {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 5px;
}

/* Stepper Content Container */
.stepper-content-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

/* Stepper Content Box */
.stepper-content {
  display: none;
  opacity: 0;
  transition: opacity 0.3s;
}

.stepper-content.visible {
  display: block;
  opacity: 1;
}

.content-box {
  width: 100%;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Media Queries for Responsive Layout */
@media (max-width: 768px) {
  .steps-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .steps {
    flex-direction: column;
  }

  .step {
    margin-bottom: 10px;
  }

  .content-box {
    padding: 15px;
  }
}

/* ---------- */
.transaction-summary {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  background-color: #fff;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label {
  font-weight: bold;
  font-size: 16px;
}

.amount {
  text-align: right;
  font-size: 16px;
}

.border-bottom {
  border-bottom: 1px solid #ddd;
}

.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

/* ---------- */

.btn-active {
  background-color: green;
}
.btn-inactive {
  background-color: red;
}
.btn-active,
.btn-inactive {
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 80px;
}

.btn-active:hover,
.btn-inactive:hover {
  opacity: 0.7;
}

.btn-active:disabled,
.btn-inactive:disabled {
  background-color: gray;
  opacity: 0.5;
  cursor: not-allowed;
}

.bg-disabled {
  background-color: #8dc1ba !important;
}

/* credit card */

/* .credit-card {
  width: 300px;
  height: 180px;
  border-radius: 12px;
  padding: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  font-family: "Arial", sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: linear-gradient(135deg, #1e5799, #2989d8);
}

.credit-card--dark {
  background: linear-gradient(135deg, #000, #333);
} */

#card-number-copy {
  height: 24px;
  width: 24px;
  position: absolute;
  top: 0px;
  right: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../assets/image/copy.png");
}

#card-number-copy-success {
  display: none;
  position: absolute;
  top: 0px;
  right: 40px;
  height: 24px;
  width: 24px;
  background-image: url("../assets/image/check.png");
  background-size: 100%;
}

.credit-card {
  width: 300px;
  height: 180px;
  border-radius: 12px;
  padding: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  font-family: "Arial", sans-serif;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* background: linear-gradient(135deg, #1e5799, #2989d8); */
  overflow: hidden; /* Ensures the mask doesn't spill outside */
}

.credit-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../assets/image/card.jpg") center/cover no-repeat;
  z-index: 0;
  opacity: 1; /* Controls how visible the background image is */
}

.credit-card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Black shadow mask */
  z-index: 0;
}

.credit-card > * {
  position: relative;
  z-index: 2; /* Ensures content is on top of the mask */
}

.credit-card--dark {
  /* background: linear-gradient(135deg, #000, #333); */
}

.credit-card__background {
  position: absolute;
  top: 20px;
  right: 20px;
}

.credit-card__logo {
  width: 40px;
  height: auto;
}

.credit-card__content {
  z-index: 1;
  padding-top: 0px;
}

.credit-card__number {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.credit-card__details {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  margin-top: 20px;
}

.credit-card__additional-details {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  margin-top: 10px;
}

.credit-card__holder span,
.credit-card__expiry span,
.credit-card__additional-details span {
  font-size: 0.7em;
  color: rgba(255, 255, 255, 0.7);
}

.credit-card__holder p,
.credit-card__expiry p,
.credit-card__additional-details p {
  margin: 0;
  font-size: 1em;
  font-weight: bold;
}

.credit-card__actions {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.credit-card__reveal-button {
  background: #f0f0f0;
  color: #007bff;
  padding: 2px 8px;
  border-radius: 5px;
  font-size: 0.85rem;
  text-transform: capitalize;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}
.credit-card__reveal-button.hide {
  display: none;
}

.credit-card__reveal-button:hover {
  color: #ff5757;
}

.credit-card__countdown {
  background: #f0f0f0;
  color: #007bff;
  padding: 2px 8px;
  border-radius: 5px;
  font-size: 0.85rem;
  text-transform: capitalize;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* card management details */

.application-details {
  max-width: 800px;
  /* margin: 0 auto; */
  /* padding: 20px; */
  font-family: "Arial", sans-serif;
}

h3 {
  color: #333;
  margin-bottom: 15px;
}

.applicant-section,
.account-info-section,
.earnings-section {
  background: white;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.account-info-section,
.earnings-section {
  background-color: #e5edf8;
  margin-top: 20px;
}

.applicant-info {
  display: flex;
  align-items: center;
}

.applicant-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
  border: 2px solid #ff8400;
}

.applicant-details h2 {
  margin: 0;
  font-size: 1.5em;
  color: #ff4400;
}

.applicant-details p {
  margin: 5px 0;
  color: #555;
}

.applicant-email,
.account-no,
.account-email {
  line-break: anywhere;
}

.action-buttons {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.btn {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.accept-btn {
  background-color: #28a745;
  color: white;
}

.accept-btn:hover {
  background-color: #218838;
}

.reject-btn {
  background-color: #dc3545;
  color: white;
}

.reject-btn:hover {
  background-color: #c82333;
}

.account-info,
.earnings-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.info-item span {
  font-weight: bold;
  color: #ff4400;
}

.info-item p {
  margin: 0;
  color: #555;
  margin-left: 26px;
}

.earnings-item span {
  font-weight: bold;
  color: #ff4400;
}

.earnings-item p {
  margin: 5px 0;
  font-size: 1.2em;
  color: #555;
  margin-left: 26px;
}

.details-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.profile-section {
  text-align: center;
}

.profile-img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  border: 2px solid #007bff;
}

.action-section .btn {
  padding: 10px 20px;
}

.info-section,
.earnings-section {
  margin-top: 20px;
}

.info-section h6,
.earnings-section h6 {
  font-size: 1.2rem;
  color: #333;
}

.info-section hr,
.earnings-section hr {
  border: 1px solid #ddd;
}

.row p {
  margin: 0 0 10px;
}

/* creadit modal */

.credit-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.credit-modal {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 300px;
}

.credit-modal-actions {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.credit-modal input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* credit limit section */
.card-limit {
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-limit h3 {
  font-weight: bold;
  color: #333;
}

.limit-card {
  border: none;
  background: linear-gradient(145deg, #ffffff, #e6e6e6);
  border-radius: 15px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1),
    -5px -5px 15px rgba(255, 255, 255, 0.5);
  transition: transform 0.2s, box-shadow 0.2s;
  text-wrap: nowrap;
}

.limit-card:hover {
  transform: translateY(-5px);
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.2),
    -5px -5px 25px rgba(255, 255, 255, 0.7);
}

.card-title {
  font-size: 1rem;
  color: #555;
}

.card-text.limit-amount {
  font-size: 1rem;
  font-weight: bold;
  color: #000;
}

.limit-card .text-primary {
  color: #007bff !important;
}

.limit-card .text-success {
  color: #28a745 !important;
}

.limit-card .text-warning {
  color: #ffc107 !important;
}

/* card summary */
/* d-flex flex-wrap justify-content-between align-items-center" */
/* General Container */
.card-summary {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

/* Left Section: Summary */
.summary-details {
  flex: 1;
}

.summary-header {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.summary-list {
  padding: 0;
  margin: 0;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f0f0f0;
}

.summary-item:last-child {
  border-bottom: none;
}

.summary-label {
  font-size: 1rem;
  color: #6c757d;
  font-weight: 500;
  width: 100px;
}

.summary-value {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  display: flex;
  align-items: center;
  gap: 5px;
  /* margin-left: 10px; */
  width: 200px;
}

/* Status Styles */
.status {
  color: #28a745;
  display: flex;
  align-items: center;
}
.status.pending {
  color: #ffc107;
}

.status-icon {
  margin-right: 5px;
  font-size: 1.2rem;
}

/* Badge Style */
.type-badge {
  background: #f0f0f0;
  color: #007bff;
  padding: 2px 8px;
  border-radius: 5px;
  font-size: 0.85rem;
  text-transform: capitalize;
}

/* Copy Icon */
.copy-icon {
  cursor: pointer;
  margin-left: 10px;
  font-size: 1rem;
  color: #007bff;
}

.copy-icon:hover {
  color: #0056b3;
}

/* Right Section: Card Display */
.card-display {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 400px;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.virtual-card {
  background: linear-gradient(145deg, #3c4b72, #2d3654);
  color: #fff;
  padding: 20px;
  border-radius: 15px;
  font-size: 0.9rem;
  position: relative;
}

.card-status {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #28a745;
  font-size: 0.85rem;
  font-weight: bold;
}

.card-details {
  text-align: center;
  margin-top: 30px;
}

.card-expiry,
.cardholder,
.card-number {
  margin-bottom: 10px;
}

/* .card-container{
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.1), -5px -5px 15px rgba(255, 255, 255, 0.5);
  transition: transform 0.2s, box-shadow 0.2s;
}

.card-container:hover {
  transform: translateY(-5px);
  box-shadow: 5px 5px 45px rgba(0, 0, 0, 0.2), -5px -5px 25px rgba(255, 255, 255, 0.7);
} */

.card-container {
  border-radius: 10px;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.1),
    -5px -5px 15px rgba(255, 255, 255, 0.5);
  transition: transform 0.3s ease, box-shadow 0.5s ease-in-out;
  transform: perspective(1000px) rotateX(0deg) rotateY(0deg);
  transform: scale(1.05);
  transform-origin: center;
  overflow: hidden;
  will-change: transform, box-shadow;
}

.card-container:hover {
  transform: perspective(1000px) rotateX(-10deg) rotateY(-10deg) translateY(0px);
  box-shadow: 8px 8px 45px rgba(0, 0, 0, 0.2),
    -8px -8px 25px rgba(255, 255, 255, 0.7);
}
